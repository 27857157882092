import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private router: Router, private toastService: ToastService) {
  }

  httpErrorMessage(errResp: HttpErrorResponse) {
    if (errResp.error?.cod) {
      this.toastService.show({
          closeButtonClass: 'text-danger',
          delay: 1000000,
          classname: 'bg-toast-error border-danger text-danger fw-semibold',
          innerHtml: `<span class="p-2 me-2"><i class="fa-solid fa-circle-exclamation fa-xl"></i></span>${errResp.error.message}<br>Cod: ${errResp.error.cod}`,
        },
      );
    } else if (errResp.status == 500) {

      this.toastService.show({
          closeButtonClass: 'text-danger',
          delay: 1000000,
          classname: 'bg-toast-error border-danger text-danger fw-semibold',
          innerHtml: `<span class="p-2 me-2"><i class="fa-solid fa-circle-exclamation fa-xl"></i></span>Errore interno, riprova più tardi.`,
        },
      );
    } else {
      const error = errResp.error;
      if (errResp.status == 401) {
        this.toastService
          .show({
            closeButtonClass:'text-danger',
            delay: 5000,
            classname: 'bg-toast-error border-danger text-danger fw-semibold',
            innerHtml: `<span class="p-2 me-2"><i class="fa-solid fa-circle-exclamation fa-xl"></i></span>Sessione scaduta.`,
          });
        this.router.navigate(['login']);
      } else if (errResp.status == 403) {
        this.toastService.show({
          delay: 5000,
          closeButtonClass: 'text-warning',
          classname: 'bg-toast-warning border-warning text-warning fw-semibold',
          innerHtml: `<span class="p-2 me-2"><i class="fa-solid fa-triangle-exclamation fa-xl"></i></span>Operazione non consentita.`,
        });

      }
    }
  }
}
