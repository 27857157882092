import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AzureBlobTokenInfo } from 'src/app/model/azure-blob-token-info';
import { CustomerInfo } from 'src/app/model/customer-info';
import { EucarisInfo } from 'src/app/model/eucaris-info';
import { UploadDto } from 'src/app/model/upload-dto';
import { BlobStorageService } from 'src/app/service/blob-storage.service';
import { EucarisService } from 'src/app/service/eucaris.service';
import { LoginService } from 'src/app/service/login.service';
import { StateService } from 'src/app/service/state.service';
import { UploadFileService } from 'src/app/service/upload-file.service';
import { UserService } from 'src/app/service/user.service';
import { UtilsService } from 'src/app/service/utils.service';
import { environment } from 'src/environments/environment';
import { LogsComponent } from '../logs/logs.component';
import { DateRangePickerComponent } from '../utils/date-range-picker/date-range-picker.component';
import { ProcessingTypeService } from 'src/app/service/processing-type.service';

@Component({
  selector: 'app-uploads-list',
  templateUrl: './uploads-list.component.html',
  styleUrls: ['./uploads-list.component.css'],
})
export class UploadsListComponent {
  @ViewChild('daAlDatePicker')
  daAlDatePicker!: DateRangePickerComponent;

  uploadedList: UploadDto[] = [];
  pageSize: number = 8;
  numberOfPage: number[] = [];
  currentCustomer: CustomerInfo | null = null;
  currentPage = 1;

  startingPageIndex = 0;

  filterForm = new FormGroup({
    da: new FormControl(),
    al: new FormControl(),
    type: new FormControl(),
    customer: new FormControl(),
    state: new FormControl(),
    notState: new FormControl(),
    processingType: new FormControl(),
    uploadDate: new FormControl(),
  });


  additionalFilterForm = new FormGroup({
    tipoLavorazione: new FormControl(false),
    dFrom: new FormControl(false),
    dUpload: new FormControl(false),
  });

  appliedFilter = {
    tipoLavorazione: false,
    dFrom: false,
    dUpload: false,
  };

  editableRow: number | null = null;

  sasToken: AzureBlobTokenInfo | undefined;
  blobServiceClient: BlobServiceClient | undefined;
  blobContainerClient: ContainerClient | undefined;

  placeholderDateRange = {
    da: 'Periodo da dd/MM/yyyy',
    al: 'Perido al dd/MM/yyyy',
  };
  totalElement: number = 0;

  uploadType: string[] = [];
  customers: CustomerInfo[] = [];

  uploading = false;

  operationView = false;

  states: {
    cod: string;
    description: string;
    nextState: { cod: string; description: string } | null;
    alwaysActivable: boolean;
  }[] = [];

  processingTypes: {
    cod: string;
    description: string;
  }[] = [];

  eucarisInfo: EucarisInfo | undefined;

  newStateSelected = '';

  newProcessTypeSelected = '';

  currentUpload:
    | {
    nomeFile: string;
    type: string;
    nverbali: number;
    dateFrom: Date;
    dateTo: Date;
    percentage: number;
  }
    | undefined;

  sort: {
    field: string | null;
    dir?: 'asc' | 'desc' | null;
  } = { field: null, dir: null };

  constructor(
    private uploadFileService: UploadFileService,
    private userService: UserService,
    private eucarisService: EucarisService,
    private utilsService: UtilsService,
    private blobStorageService: BlobStorageService,
    private stateService: StateService,
    private modalService: NgbModal,
    private loginService: LoginService,
    private processingTypeService: ProcessingTypeService,
  ) {
    this.stateService.getState().subscribe((resp) => {
      this.states = resp;
    });

    this.processingTypeService.getProcessingTypes().subscribe((resp) => {
      this.processingTypes = resp;
    });

    this.loginService.getUserInfo().subscribe({
      next: (resp) => {
        if ((resp.userGroup as string).toLowerCase() == 'op') {
          this.operationView = true;
          this.getUploaded();
        }

        this.customers = resp.customers;
      },
    });

    this.userService.uploadStatus.subscribe((resp) => {
      if (resp.isUploading && resp.percentage) {
        this.uploading = true;

        if (resp.uploadForm && resp.nomeFile) {
          this.currentUpload = {
            nomeFile: resp.nomeFile,
            type: resp.uploadForm.get('type')?.value,
            nverbali: resp.uploadForm.get('nverbali')?.value,
            dateFrom: resp.uploadForm.get('dateFrom')?.value,
            dateTo: resp.uploadForm.get('dateTo')?.value,
            percentage: Number.parseFloat(resp.percentage.toFixed(2)),
          };
        }
      } else {
        if (this.uploading) {
          this.getUploaded();
        }
        this.uploading = false;
      }
    });

    this.userService.customerCode.subscribe((customer) => {
      this.currentCustomer = customer;
      this.getUploaded();
    });

    uploadFileService.getUploadsType().subscribe({
      next: (resp) => {
        this.uploadType = resp;
      },
    });

    this.userService.customerCode.subscribe((customer) => {
      if (customer)
        this.eucarisService.getEucarisInfo(customer.cod).subscribe({
          next: (resp) => {
            this.eucarisInfo = resp;

            this.initBlobConfig();
          },
          error: (err) => this.utilsService.httpErrorMessage(err),
        });
    });
    this.applyFilter();
  }


  getClassByState(state: string) {
    switch (state.toLowerCase()) {
      case 'c':
        return 'text-256eb3';
      case 'del':
        return 'text-danger';
      case 'cp':
      case 'pco':
        return 'text-c96311';
      default :
        return 'text-256eb3';
    }
  }

  getIconByState(state: string) {
    switch (state.toLowerCase()) {
      case 'p':
        return 'circle-dashed';
      case 'del':
        return 'x';
      case 'cp':
      case 'pco':
        return 'circle-notch';
      default :
        return 'circle';
    }
  }

  openLogs(idFlusso: number) {
    const modalRef = this.modalService.open(LogsComponent, {
      size: 'xl',
      centered: true,
    });

    modalRef.componentInstance.idFlusso = idFlusso;
    modalRef.componentInstance.operationView = this.operationView;
  }

  editRow(idx: number) {
    this.editableRow = idx;
  }

  changeState(value: UploadDto) {
    if (
      (this.newStateSelected &&
        value.state.cod.toLowerCase() != this.newStateSelected.toLowerCase()) ||
      (this.newProcessTypeSelected &&
        value.processingType?.cod.toLowerCase() !=
        this.newProcessTypeSelected.toLowerCase())
    ) {
      this.uploadFileService
        .updateState({
          id: value.id,
          stateCod: this.newStateSelected
            ? this.newStateSelected
            : value.state.cod,
          processingTypeCod: this.newProcessTypeSelected
            ? this.newProcessTypeSelected
            : value.processingType
              ? value.processingType.cod
              : null,
        })
        .subscribe({
          next: (resp) => {
            this.getUploaded();
          },
          error: (err) => this.utilsService.httpErrorMessage(err),
        });
    }

    this.editableRow = null;
  }

  initBlobConfig() {
    this.blobContainerClient = undefined;

    const customerInfo = this.userService.customerCode.getValue();

    if (customerInfo)
      this.blobStorageService
        .getBlobToken(customerInfo.cod)
        .subscribe((resp) => {
          this.sasToken = resp;
          this.getBlobServiceClient();
        });
  }

  getBlobServiceClient() {
    if (this.sasToken) {
      this.blobServiceClient = new BlobServiceClient(
        `https://${this.sasToken.account}.blob.core.windows.net?${this.sasToken.token}`,
      );

      this.blobContainerClient = this.blobServiceClient.getContainerClient(
        this.sasToken.containerName,
      );
    }
  }

  getDownloadLink(upload: UploadDto, e?: Event) {
    if (e) {
      e.preventDefault();
    }

    if (this.operationView) {
      this.blobContainerClient = undefined;

      const customerCod = upload.customerCod;

      if (customerCod)
        this.blobStorageService.getBlobToken(customerCod).subscribe((resp) => {
          this.sasToken = resp;
          this.getBlobServiceClient();

          if (this.blobContainerClient) {
            const link = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.setAttribute(
              'href',
              this.blobContainerClient.getBlobClient(
                `${environment.uploadFolderName}/${upload.filename}`,
              ).url,
            );
            link.setAttribute('download', upload.filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        });
    }

    if (this.blobContainerClient) {
      return this.blobContainerClient.getBlobClient(
        `${
          this.eucarisInfo?.twoStep
            ? environment.tmpFolderName
            : environment.uploadFolderName
        }/${upload.filename}`,
      ).url;
    } else {
      return '';
    }
  }

  reset() {
    this.daAlDatePicker.reset();
    this.filterForm.reset();
  }

  changePage(page: number) {
    this.currentPage = page;
    this.getUploaded();
  }

  changeStartingPage(string: 'add' | 'subtract') {
    if (string == 'add') {
      this.startingPageIndex += 10;
    } else {
      this.startingPageIndex -= 10;
    }
  }

  getUploaded() {
    this.editableRow = null;
    // if (this.operationView || this.currentCustomer)
    this.uploadFileService
      .getUploaded(
        this.currentCustomer ? this.currentCustomer.cod : null,
        this.pageSize > 0 ? this.pageSize : null,
        this.currentPage > 0 ? this.currentPage : null,
        this.sort.dir && this.sort.field
          ? (this.sort as { field: string; dir: 'asc' | 'desc' })
          : null,
        {
          dateFrom: this.filterForm.controls.al.value &&
          !this.filterForm.controls.da.value
            ?this.ngbDateToDate(this.filterForm.controls.al.value):  this.filterForm.controls.da.value ? this.ngbDateToDate(this.filterForm.controls.da.value) : null,
          dateTo:
            this.filterForm.controls.da.value &&
            !this.filterForm.controls.al.value
              ? this.ngbDateToDate(this.filterForm.controls.da.value)
              : this.filterForm.controls.al.value ? this.ngbDateToDate(this.filterForm.controls.al.value):null,
          type: this.filterForm.controls.type.value,
          customerCod: this.filterForm.controls.customer.value,
          stateCod: this.filterForm.controls.state.value,
          notStateCod: this.filterForm.controls.notState.value,
          processingTypeCod: this.filterForm.controls.processingType.value,
          uploadedDateFrom: this.filterForm.controls.uploadDate.value
            ? this.ngbDateToDate(this.filterForm.controls.uploadDate.value)
            : null,
          uploadedDateTo: this.filterForm.controls.uploadDate.value
            ? this.ngbDateToDate(this.filterForm.controls.uploadDate.value)
            : null,
        },
      )
      .subscribe({
        next: (resp) => {
          this.uploadedList = resp.data;

          this.numberOfPage = [];
          const pages = resp.lastPage;
          for (let index = 1; index <= pages; index++) {
            this.numberOfPage.push(index);
          }
          this.totalElement = resp.totalElement;
        },
        error: (err) => this.utilsService.httpErrorMessage(err),
      });
  }

  ngbDateToDate(ngbDate: NgbDate) {
    const date = new Date();
    date.setUTCFullYear(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    return date;
  }

  getUploadedCsv() {
    if (this.operationView || this.currentCustomer) {
      this.uploadFileService
        .getUploadedCsv(
          this.currentCustomer ? this.currentCustomer.cod : null,
          this.pageSize > 0 ? this.pageSize : null,
          this.currentPage > 0 ? this.currentPage : null,
          {
            dateFrom: this.filterForm.controls.da.value,
            dateTo:
              this.filterForm.controls.da.value &&
              !this.filterForm.controls.al.value
                ? new Date()
                : this.filterForm.controls.al.value,
            type: this.filterForm.controls.type.value,
            customerCod: this.filterForm.controls.customer.value,
            stateCod: this.filterForm.controls.state.value,
            notStateCod: this.filterForm.controls.notState.value,
            processingTypeCod: this.filterForm.controls.processingType.value,
          },
        )
        .subscribe((resp) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(resp);
          link.download = 'uploaded.csv';
          link.click();
        });
    }
  }

  changePageSize() {
    this.getUploaded();
  }

  getCurrentElementRange() {
    if (this.pageSize > 0 && this.totalElement > 0) {
      return `${this.currentPage * this.pageSize - this.pageSize + 1} - ${
        this.currentPage * this.pageSize < this.totalElement
          ? this.currentPage * this.pageSize
          : this.totalElement
      } / ${this.totalElement}`;
    }
    return `${this.totalElement} / ${this.totalElement}`;
  }

  sortData(column: string) {
    if (column === this.sort.field) {
      this.sort.dir = this.sort.dir === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = column;
      this.sort.dir = 'asc';
    }
    this.getUploaded();
  }

  applyFilter() {
    this.appliedFilter = this.additionalFilterForm.getRawValue() as {
      tipoLavorazione: boolean,
      dFrom: boolean,
      dUpload: boolean
    };

    if (this.appliedFilter.tipoLavorazione) {
      this.filterForm.controls.processingType.enable();
    } else {
      this.filterForm.controls.processingType.setValue(null);
      this.filterForm.controls.processingType.disable();
    }

    if (this.appliedFilter.dFrom) {
      this.filterForm.controls.da.enable();
    } else {
      this.filterForm.controls.da.setValue(null);
      this.filterForm.controls.da.disable();
    }
    if (this.appliedFilter.dUpload) {
      this.filterForm.controls.uploadDate.enable();
    } else {
      this.filterForm.controls.uploadDate.setValue(null);
      this.filterForm.controls.uploadDate.disable();
    }

  }

  excludeCompleted($event: any) {
    if ($event.target.checked) {
      this.filterForm.controls.notState.setValue('CP');
    } else {
      this.filterForm.controls.notState.setValue(null);
    }

    this.getUploaded();
  }
}
