import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProcessingTypeService {

  constructor(private httpClient: HttpClient) {}

  getProcessingTypes() {
    return this.httpClient.get(
      `${environment.baseUrl}${environment.processingTypePath}`
    ) as Observable<
      {
        cod: string;
        description: string;
        nextState: null | { cod: string; description: string };
      }[]
    >;
  }
}
