import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MessageComponent } from '../components/message/message.component';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private modalService: NgbModal) {}

  openMessage(
    title:string,
    message: string,
    code: string,
    isError: boolean,
    action?: {
      redirectAction?: { link: string; btnMsg: string };
      leftActionButton?: { label: string; action: Function };
      rigthActionButton?: { label: string; action: Function };
    }
  ): NgbModalRef {

    const modalRef = this.modalService.open(MessageComponent, {centered:true});
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.isError = isError;
    modalRef.componentInstance.title = title
    if (action) modalRef.componentInstance.action = action;
    // if (redirectAction != null)
    //   modalRef.componentInstance.redirectAction = redirectAction;

    return modalRef;
  }
}
