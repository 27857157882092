import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Traking } from 'src/app/model/traking';
import { LoginService } from 'src/app/service/login.service';
import { UploadFileService } from 'src/app/service/upload-file.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css'],
})
export class LogsComponent {
  @Input() idFlusso: number | undefined;
  @Input() operationView = false;

  trakings: Traking[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private uploadService: UploadFileService,
    private userService: LoginService
  ) {}

  ngOnInit() {
    if (this.idFlusso)
      this.uploadService.getTraking(this.idFlusso).subscribe((resp) => {
        this.trakings = resp;
      });
  }
}
