<div class="bg-white pt-4" style="height: calc(100vh - 60px)">
  <!--<div class="d-flex justify-content-center pb-4 mt-4">-->
  <!--  <div class="text-xl-center title">Conferma invio</div>-->
  <!--</div>-->

  <div class="d-flex justify-content-center">
    <div class="border p-4 rounded-5 col-lg-5">
      @if (upload?.sended) {
        <p class="p-1">
          Il file <span class="fw-bold"> {{ upload?.originalFilename }}</span> è stato già inviato.
        </p>
      } @else {
        <p class="p-1">
          Vuoi confermare l'invio del file <span class="fw-bold"> {{ upload?.originalFilename }}</span
          >?
        </p>
        <!-- Submit button -->
        <button
          type="button"
          [disabled]="upload?.sended"
          (click)="deleteUpload()"
          [hidden]="upload?.sended"
          class="btn fw-bold text-256eb3 col-4 col-4">
          Elimina
        </button>
        <!-- Submit button -->
        <button
          type="button"
          [disabled]="upload?.sended"
          (click)="confirmUpload()"
          class="btn btn-primary fw-bold col-4"
          [ngClass]="upload?.sended ? 'offset-8' : 'offset-4'">
          Invia
        </button>
      }

      <ng-template #sended>
        <p class="p-1">Il file {{ upload?.originalFilename }} è stato già inviato.</p>
      </ng-template>
    </div>
  </div>
</div>
