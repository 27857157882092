import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/service/utils.service';
import { LoginService } from '../../service/login.service';
import { ToastService } from '../../service/toast.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent {
  forgotCredentialForm = new FormGroup({
    email: new FormControl('', Validators.required),
  });

  constructor(
    private loginService: LoginService,
    private router: Router,
    private toastService: ToastService,
    private utilsService: UtilsService,
  ) {
  }

  ngOnInit() {
    this.loginService.checkAuth().subscribe((resp) => {
      if (resp) {
        this.router.navigate([/*this.userService.userMenu.value[0].route*/ 'uploaded']);
      }
    });
  }

  submit() {
    if (this.forgotCredentialForm.valid) {
      this.loginService
        .forgotCredential(
          this.forgotCredentialForm.controls.email.value
            ? this.forgotCredentialForm.controls.email.value
            : '',
        )
        .subscribe({
          next: (resp) => {

            this.toastService.show({
              classname: 'bg-toast-success border-success',
              delay: 5000,
              innerHtml:
                `<span class="p-2 rounded-circle bg-d1ebe2 me-2"><i class="fa-regular fa-thumbs-up text-success fa-xl"></i></span>Operazione eseguita con successo`,
            });


            this.router.navigate(['login']);
          },
          error: (err) => this.utilsService.httpErrorMessage(err),
        });
    } else {
    }
  }
}
