<div class="modal-header border-0">
  <h4 class="modal-title ms-1 row fw-bold" id="modal-basic-title">Storico modifiche stato</h4>
</div>
<div class="modal-body p-4">
  <div class="row bg-f3fbff text-header fw-bold text-uppercase mt-3 m-0 p-2 rounded small">
    <div class="col border-end">Stato</div>
    <div class="col" [ngClass]="operationView ? 'border-end' : ''">Data</div>
    @if (operationView) {
      <div class="col">Nominativo</div>
    }
  </div>

  @for (traking of trakings; track traking) {
    <div class="row mt-2 m-0 p-2 rounded d-flex align-items-center small list-row bg-f8f8f9">
      <div class="col border-end text-truncate">{{ traking.fkState.description }}</div>
      <div class="col" [ngClass]="operationView ? 'border-end' : ''">
        {{ traking.date | date: 'dd/MM/yyyy' }} <br /><small class="">Ore: {{ traking.date | date: 'HH:mm' }}</small>
      </div>
      @if (operationView) {
        <div class="col">{{ traking.user }}</div>
      }
    </div>
  }
</div>

<div class="modal-footer bg-e0ecf2">
  <button class="btn bg-256eb3 fw-bold border-0 text-white ps-3 pe-3" (click)="activeModal.close()">Chiudi</button>
</div>
