@if (uploading && currentUpload) {
  <div class="bg-white">
    <div class="row rounded p-2 mt-4 fw-bold text-center text-white shadow bg-4d94db">
      <div>Caricamento in corso</div>
    </div>

    <div class="row mt-3 p-2 rounded text-center shadow">
      <div class="col-3 border-end text-truncate">{{ currentUpload.nomeFile }}</div>
      <div class="col-2 border-end">{{ currentUpload.type | capitalize }}</div>
      <div class="col-2 border-end">{{ currentUpload.nverbali }}</div>
      <div class="col-3 border-end">
        {{ currentUpload.dateFrom | date: 'dd/MM/yyyy' }} - {{ currentUpload.dateTo | date: 'dd/MM/yyyy' }}
      </div>
      <div class="col-2">
        <ngb-progressbar
          [showValue]="true"
          type="primary"
          [striped]="true"
          [animated]="true"
          [value]="currentUpload.percentage"></ngb-progressbar>
      </div>
    </div>
  </div>
}

<div class="container-fluid p-0 bg-white">
  <form [formGroup]="filterForm" class="row m-0 align-items-center p-3">
    <div class="col-10">
      <div class="row">
        <div class="col-2 mb-2">
          <select
            formControlName="type"
            class="form-select w-content me-2 text-4d94db cursor-pointer w-100 mt-n3"
            aria-label="Default select example">
            <option [ngValue]="null" disabled selected>Seleziona tipo</option>
            @for (type of uploadType; track type) {
              <option [ngValue]="type">{{ type.replaceAll('_', ' ') }}</option>
            }
          </select>
        </div>
        <div class="col-2 mb-2">
          <select
            formControlName="state"
            class="form-select w-content me-2 text-4d94db cursor-pointer w-100"
            aria-label="Default select example">
            <option [ngValue]="null" disabled selected>Seleziona stato</option>
            @for (state of states; track state) {
              <option [ngValue]="state.cod">{{ state.description }}</option>
            }
          </select>
        </div>
        @if (operationView) {
          <div class="col-2 mb-2">
            <select
              formControlName="customer"
              class="form-select w-content me-2 text-4d94db cursor-pointer w-100"
              aria-label="Default select example">
              <option [ngValue]="null" disabled selected>Seleziona ente</option>
              @for (customer of customers; track customer) {
                <option [ngValue]="customer.cod">{{ customer.descrizione }}</option>
              }
            </select>
          </div>
        }
        <!--        <div class="col-2 mb-2">-->
        <!--          <div class="input-group date-picker">-->
        <!--            <input-->
        <!--              class="form-control"-->
        <!--              placeholder="Scadenza"-->
        <!--              formControlName="al"-->
        <!--              name="dp"-->
        <!--              ngbDatepicker-->
        <!--              #d="ngbDatepicker" />-->
        <!--            <button-->
        <!--              class="btn btn-outline-secondary fa-regular fa-calendar"-->
        <!--              (click)="d.toggle()"-->
        <!--              type="button"></button>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="col-2 mb-2" [hidden]="!appliedFilter.tipoLavorazione">
          <select
            formControlName="processingType"
            class="form-select w-content me-2 text-4d94db cursor-pointer w-100"
            aria-label="Default select example">
            <option [ngValue]="null" disabled selected>Seleziona tipo lavorazione</option>
            @for (type of processingTypes; track type) {
              <option [ngValue]="type.cod">{{ type.description }}</option>
            }
          </select>
        </div>

        @if (appliedFilter.dFrom) {
          <div class="col-2 mb-2">
            <div class="input-group date-picker">
              <input
                class="form-control"
                placeholder="Data primo verbale"
                formControlName="da"
                name="dp"
                ngbDatepicker
                #dFirstViolationFine="ngbDatepicker" />
              <button
                class="btn btn-outline-secondary fa-regular fa-calendar"
                (click)="dFirstViolationFine.toggle()"
                type="button"></button>
            </div>
          </div>
        }
        @if (appliedFilter.dUpload) {
          <div class="col-2 mb-2">
            <div class="input-group date-picker">
              <input
                class="form-control"
                placeholder="Data Caricamento"
                formControlName="uploadDate"
                name="dp"
                ngbDatepicker
                #dUpload="ngbDatepicker" />
              <button
                class="btn btn-outline-secondary fa-regular fa-calendar"
                (click)="dUpload.toggle()"
                type="button"></button>
            </div>
          </div>
        }
      </div>
    </div>
    <div class="col d-flex flex-row-reverse">
      <button
        class="rounded search-button d-flex align-items-center border pb-2 pt-2 ps-3 pe-3 text-white bg-256eb3 fw-bolder"
        (click)="getUploaded()">
        <i class="fa-solid fa-magnifying-glass"></i>
        <small class="ms-2">Cerca</small>
      </button>
      <div class="pt-2 pb-2 ps-3 pe-3">
        <div class="h-100">
          <div class="border-start h-100"></div>
        </div>
      </div>
      <button
        class="rounded search-button text-black d-flex align-items-center p-2 border"
        [autoClose]="false"
        [ngbPopover]="filterSelection"
        #popover="ngbPopover">
        <i class="fa-solid fa-filter"></i>
      </button>
      <ng-template #filterSelection>
        <form [formGroup]="additionalFilterForm">
          <div class="form-check">
            <input class="form-check-input" formControlName="dFrom" type="checkbox" value="" />
            <label class="form-check-label"> Data primo verbale </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" formControlName="dUpload" type="checkbox" value="" />
            <label class="form-check-label"> Data caricamento </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" formControlName="tipoLavorazione" type="checkbox" value="" />
            <label class="form-check-label"> Tipo di lavorazione </label>
          </div>

          <div class="d-inline-flex">
            <button
              class="btn me-2 text-primary fw-bold p-0"
              (click)="additionalFilterForm.reset(); applyFilter(); popover.close()">
              <small><i class="fa-solid fa-x me-1 fs-6"></i></small>
              Cancella
            </button>
            <button class="btn btn-secondary fw-bold" (click)="applyFilter(); popover.close()">Applica</button>
          </div>
        </form>
      </ng-template>
    </div>
  </form>
  <div class="p-0 m-0 border-bottom container-fluid"></div>
  <div class="p-2 m-0 row">
    <div class="col ps-4 d-flex d-flex-row small align-items-center">
      <span class="fw-bold align-middle">{{ totalElement }}</span>
      <span class="ms-1 align-middle"> Rilevazioni totali </span>

      <div class="ps-3 pe-3">
        <div class="vr h-100"></div>
      </div>

      <span class="fw-bold me-2">Escludi lotti completati</span>
      <div class="form-check form-switch fw-bold d-flex align-items-center">
        <input
          class="form-check-input switches-color"
          type="checkbox"
          (change)="excludeCompleted($event)"
          id="flexSwitchCheckDefault" />
      </div>
      <div class="ps-3 pe-3">
        <div class="vr h-100"></div>
      </div>
      <button class="btn color-256eb3 fw-bold p-0" (click)="filterForm.reset(); getUploaded()">
        <i class="fa-regular fa-eraser"></i> Reset campi di ricerca
      </button>
    </div>
    <div class="col d-flex align-items-center flex-row-reverse">
      <button
        class="rounded search-button h-100 border pb-2 pt-2 ps-3 pe-3 color-256eb3 bg-white fw-bolder"
        (click)="getUploaded()">
        <i class="fa-solid fa-rotate-right fa-lg"></i>
      </button>
      <div class="pt-2 pb-2 ps-3 pe-3 h-100">
        <div class="h-100 border-start"></div>
      </div>
      <button class="rounded search-button text-black h-100 p-2 border" (click)="getUploadedCsv()">
        <i class="fa-solid fa-file-spreadsheet"></i><small class="fw-bold"> Export CSV</small>
      </button>
    </div>
  </div>
</div>
<div class="container-fluid pt-2 pb-4">
  @for (upload of uploadedList; track upload; let idx = $index) {
    @if (idx == 0) {
      <div class="row bg-f3fbff text-header fw-bold text-uppercase mt-3 m-0 p-2 rounded small">
        <small class="col" (click)="sortData('id')"
        >ID
          @if ((sort.dir === 'asc' || sort.dir === 'desc') && sort.field === 'id') {
            <i
              class="position-absolute ms-1 fa-solid lh-inherit"
              [ngClass]="{
                'fa-up-long': sort.dir === 'asc',
                'fa-down-long': sort.dir === 'desc'
              }"></i>
          }
        </small>

        <small class="col-2">Nome file</small>

        @if (operationView) {
          <small class="col">Ente</small>
        }
        <small class="col">Tipologia</small>
        <small class="col cursor-pointer" (click)="sortData('dinsert')"
        >caricamento
          @if ((sort.dir === 'asc' || sort.dir === 'desc') && sort.field === 'dinsert') {
            <i
              class="position-absolute ms-1 fa-solid lh-inherit"
              [ngClass]="{
                'fa-up-long': sort.dir === 'asc',
                'fa-down-long': sort.dir === 'desc'
              }"></i>
          }
        </small>
        <small class="col" (click)="sortData('dateChange')"
        >ultima modifica
          @if ((sort.dir === 'asc' || sort.dir === 'desc') && sort.field === 'dateChange') {
            <i
              class="position-absolute ms-1 fa-solid lh-inherit"
              [ngClass]="{
                'fa-up-long': sort.dir === 'asc',
                'fa-down-long': sort.dir === 'desc'
              }"></i>
          }
        </small>
        <small class="col border-start">#atti</small>
        <small class="col">periodo</small>
        <small class="col-2">stato</small>
        <small class="col">tipo lavorazione</small>
        <small class="col-2 text-end">azioni</small>
      </div>
    }
    <div class="row bg-white mt-2 m-0 p-2 rounded d-flex align-items-center small list-row">
      <small class="col">{{ upload.id }}</small>

      <small class="col-2">{{ upload.originalFilename }}</small>

      @if (operationView) {
        <small class="col">{{ upload.customer }}</small>
      }
      <small class="col">{{ upload.type.replaceAll('_', ' ') }}</small>
      <small class="col fw-bold"
      >{{ upload.dinsert | date: 'dd/MM/yyyy' }}<br /><span class="text-secondary">{{
          upload.dinsert | date: "'Ore: ' hh:mm"
        }}</span></small
      >
      <small class="col fw-bold"
      >{{ upload.dateChange | date: 'dd/MM/yyyy' }}<br />
        <span class="text-secondary">{{ upload.dateChange | date: "'Ore: ' hh:mm" }}</span></small
      >
      <small class="col border-start">{{ upload.nverbali }}</small>
      <small class="col fw-bold"
      >{{ upload.dateFrom | date: "'dal: ' dd/MM/yyyy" }}<br />
        <span class="">{{ upload.dateTo | date: "'al: ' dd/MM/yyyy" }}</span></small
      >
      <div class="col-2">
        @if (editableRow == idx) {
          <select
            class="form-select small"
            aria-label="Default select example"
            [ngModel]="upload.state.cod"
            (ngModelChange)="newStateSelected = $event">
            @for (state of states; track state) {
              <option
                class="fw-semibold"
                [ngClass]="
                  !state.alwaysActivable &&
                  !upload.state.alwaysActivable &&
                  upload.state.nextState?.cod != state.cod &&
                  upload.state.cod != state.cod
                    ? 'text-secondary'
                    : 'text-256eb3'
                "
                [ngValue]="state.cod"
                [disabled]="
                  !state.alwaysActivable &&
                  !upload.state.alwaysActivable &&
                  upload.state.nextState?.cod != state.cod &&
                  upload.state.cod != state.cod
                ">
                {{ state.description }}
              </option>
            }
          </select>
        } @else {
          <small [class]="[getClassByState(upload.state.cod), 'fw-bold']">
            <i [class]="'fa-solid fa-' + getIconByState(upload.state.cod)"></i> {{ upload.state.description }}</small
          >
        }
      </div>

      <div class="col">
        @if (editableRow == idx) {
          <select
            class="form-select small"
            aria-label="Default select example"
            [ngModel]="upload.processingType?.cod"
            (ngModelChange)="newProcessTypeSelected = $event">
            <option [ngValue]="null" disabled>Tipo Processo</option>
            @for (processingType of processingTypes; track processingType) {
              <option [ngValue]="processingType.cod" class="text-256eb3 fw-semibold">
                {{ processingType.description }}
              </option>
            }
          </select>
        } @else {
          <small> {{ upload.processingType?.description }} </small>
        }
      </div>
      <small class="col-2 d-flex flex-row-reverse">
        @if (operationView && editableRow == idx) {
          <button
            class="btn rounded-circle bg-danger text-white ms-3 d-flex align-items-center round-button fa-solid fa-x"
            (click)="editableRow = null"></button>
          <button
            class="btn rounded-circle bg-success text-white d-flex align-items-center round-button fa-solid fa-check"
            (click)="changeState(upload)"></button>
        } @else {
          <button class="btn text-secondary" [ngbPopover]="popOverTemplate">
            <i class="bi bi-three-dots-vertical"></i>
          </button>
          <ng-template #popOverTemplate>
            <div class="row">
              @if (operationView) {
                <button class="btn" (click)="getDownloadLink(upload, $event)">Export CSV</button>
              } @else {
                <a class="btn" [href]="getDownloadLink(upload)">Export CSV</a>
              }
            </div>
            <div class="row">
              <button class="btn" (click)="openLogs(upload.id)">Visualizza Log</button>
            </div>
          </ng-template>

          @if (operationView) {
            <button class="btn text-primary fw-bold" (click)="editRow(idx)">
              <small> <i class="fa-solid fa-pen-to-square"></i> Modifica</small>
            </button>
            @if (upload.portaleBabyloLink) {
              <a [href]="upload.portaleBabyloLink" target="_blank" class="btn btn-primary fw-bold">
                <small>
                  <i class="fa-solid fa-arrow-up-right-from-square"></i>
                  Lavora
                </small>
              </a>
            }
          }
        }
      </small>
    </div>
  }

  @if (numberOfPage) {
    <div class="row rounded mt-2 m-0 fw-bold bg-f3fbff text-header">
      <div class="col d-flex flex-row-reverse">
        <nav class="d-contents">
          <ul class="pagination m-0">
            <li class="page-item cursor-pointer">
              <a class="page-link bg-transparent border-0" (click)="changePage(1)" aria-label="Previous">
                <i class="bi bi-skip-start-fill"></i>
              </a>
            </li>
            @if (numberOfPage.length > 10 && startingPageIndex > 0) {
              <li class="page-item cursor-pointer">
                <a class="page-link bg-transparent border-0" (click)="changeStartingPage('subtract')"
                ><i class="bi bi-caret-left-fill"></i
                ></a>
              </li>
            }
            @for (page of numberOfPage | slice: startingPageIndex : startingPageIndex + 10; track page) {
              <li class="page-item cursor-pointer">
                <a class="page-link bg-transparent border-0" (click)="changePage(page)">{{ page }}</a>
              </li>
            }
            @if (numberOfPage.length > 10 && startingPageIndex < numberOfPage.length - 10) {
              <li class="page-item cursor-pointer">
                <a class="page-link bg-transparent border-0" (click)="changeStartingPage('add')"
                ><i class="bi bi-caret-right-fill"></i
                ></a>
              </li>
            }
            <li class="page-item cursor-pointer">
              <a class="page-link bg-transparent border-0" (click)="changePage(numberOfPage.length)" aria-label="Next">
                <i class="bi bi-skip-end-fill"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  }
</div>
