import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuard } from './route-guard';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ConfirmSendComponent } from './components/confirm-send/confirm-send.component';
import { UploadsListComponent } from './components/uploads-list/uploads-list.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, title: 'Login' },
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'forgot-password', component: ForgotPasswordComponent, title:'Password dimenticata' },
  {
    path: 'upload-file',
    component: UploadFileComponent,
    canActivate: [RouteGuard],
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [RouteGuard],
    title: 'Cambia password'
  },
  {
    path: 'change-password/:token',
    component: ChangePasswordComponent,
  title: 'Cambia password'
  },
  {
    path: 'confirm-send',
    component: ConfirmSendComponent,
    canActivate: [RouteGuard],
    title:'Conferma invio'
  },
  {
    path: 'uploaded',
    component: UploadsListComponent,
    canActivate: [RouteGuard],
    title:'Rilevazioni'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
