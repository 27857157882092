import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { UtilsService } from 'src/app/service/utils.service';
import { LoginService } from '../../service/login.service';
import { MessageService } from '../../service/message.service';
import { ToastService } from '../../service/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  loginForm = new FormGroup({
    username: new FormControl(),
    password: new FormControl('', Validators.compose([Validators.required])),
  });

  constructor(
    private loginService: LoginService,
    private router: Router,
    private toastService: ToastService,
    private userService: UserService,
    private utilsService: UtilsService,
  ) {
  }

  ngOnInit() {
    this.loginService.checkAuth().subscribe((resp) => {
      if (resp) {
        this.router.navigate([/*this.userService.userMenu.value[0].route*/ 'uploaded']);
      }
    });
  }

  submit() {
    if (this.loginForm.valid) {
      this.loginService
        .login(
          this.loginForm.controls.username.value,
          this.loginForm.controls.password.value
            ? (this.loginForm.controls.password.value as string)
            : '',
        )
        .subscribe({
          next: (resp) => {
            this.loginService.autoUpdateCookie();

            this.loginService.getUserInfo().subscribe({
              next: (userInfo) => {
                if (resp.cod === '001') {
                  this.router.navigate(['change-password']);
                } else {
                  if (this.loginService.afterLoginRedirect) {
                    const redirectTo = this.loginService.afterLoginRedirect;
                    this.loginService.afterLoginRedirect = undefined;
                    this.router.navigateByUrl(redirectTo);
                  } else {
                    this.router.navigate([/*userInfo.userMenus[0].route*/ 'uploaded']);
                  }
                }
                this.userService.username.next({ username: userInfo.username, userAvatar: userInfo.userAvatar });
                this.userService.customers.next(userInfo.customers);
                this.userService.userGroup.next(userInfo.userGroup);
                this.userService.userMenu.next(userInfo.userMenus);
              },
              error: (err) => this.utilsService.httpErrorMessage(err),
            });
          },
          error: (errResp) => {
            const error = errResp.error;

            if (errResp.status == 401) {

              this.toastService
                .show({
                  closeButtonClass: 'text-danger',
                  delay: 5000,
                  classname: 'bg-toast-error border-danger text-danger fw-semibold',
                  innerHtml: `<span class="p-2 me-2"><i class="fa-solid fa-circle-exclamation fa-xl"></i></span>Credenziali errate.`,
                });
              this.loginForm.reset();

            }
          },
        });
    }
  }
}
