import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AuthInterceptor } from './components/interceptor/auth.interceptor';
import { ConfirmSendComponent } from './components/confirm-send/confirm-send.component';
import { DateRangePickerComponent } from './components/utils/date-range-picker/date-range-picker.component';
import { DragAndDropFileComponent } from './components/utils/drag-and-drop-file/drag-and-drop-file.component';
import { NgbDateITParserFormatterService } from './service/ngb-date-itparser-formatter.service';
import { UploadsListComponent } from './components/uploads-list/uploads-list.component';
import { CapitalizePipe } from './capitalize.pipe';
import { LogsComponent } from './components/logs/logs.component';
import { ToastContainerComponent } from './components/utils/toast-container/toast-container.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    UploadFileComponent,
    ChangePasswordComponent,
    ConfirmSendComponent,
    DateRangePickerComponent,
    DragAndDropFileComponent,
    UploadsListComponent,
    CapitalizePipe,
    LogsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbProgressbarModule,
    ToastContainerComponent
  ],
  providers: [
    { useClass: AuthInterceptor, provide: HTTP_INTERCEPTORS, multi: true },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateITParserFormatterService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
