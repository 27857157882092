import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './service/login.service';
import { UserService } from './service/user.service';

@Injectable({
  providedIn: 'root',
})
export class RouteGuard implements CanActivate {
  constructor(
    public loginService: LoginService,
    public router: Router,
    public userService: UserService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.isAllowed(state.url);
  }

  public async isAllowed(url: string) {
    try {
      // console.log(url);

      if (await this.loginService.isAuth()) {
        if (url === '/login' || url === '/forgot-password') {
          this.router.navigateByUrl(url.toString());
        } else {
          if (url.includes('change-password')) {
            return true;
          }
          return await this.loginService.checkRoutePermission(
            url.replace(/\?.*/, '').replace('/', '')
          );
        }
        this.loginService.checkAutoUpdateCookie();
        return true;
      } else {
        if (url.includes('confirm-send?id='))
          this.loginService.afterLoginRedirect = url.toString();
        this.router.navigateByUrl('login');
        return false;
      }
    } catch {
      this.router.navigateByUrl('login');
      return false;
    }
  }
}
