<div class="bg-white pt-4" style="height: calc(100vh - 60px)">
  <div class="d-flex justify-content-center pb-4">
    <div class="text-xl-center title">Cambia Password</div>
  </div>
  <div class="d-flex justify-content-center">
    @if (token) {
      <form [formGroup]="changePasswordWithTokeForm" class="border p-4 rounded-5" (ngSubmit)="submit()">
        <!-- New Password input -->
        <div class="form-outline mb-4">
          <input
            type="password"
            formControlName="newPassword"
            id="newPasswordInput"
            class="form-control"
            [ngClass]="
              changePasswordWithTokeForm.get('newPassword')?.valid
                ? 'valid'
                : changePasswordWithTokeForm.controls.newPassword.value
                  ? 'wrong'
                  : ''
            "
            placeholder="Nuova password"
            [required]="true" />
          @if (
            !changePasswordWithTokeForm.get('newPassword')?.valid &&
            changePasswordWithTokeForm.controls.newPassword.value
          ) {
            <label class="form-label wrong-text" for="newPassword">
              <ul>
                <li
                  checkLenght
                  [ngClass]="changePasswordWithTokeForm.controls.newPassword.value.length > 7 ? 'valid-text' : ''">
                  La password deve essere di almeno 8 caratteri
                </li>
                <li
                  checkCapitalLetter
                  [ngClass]="
                    checkCapitalLetter(changePasswordWithTokeForm.controls.newPassword.value) ? 'valid-text' : ''
                  ">
                  Deve contenere almeno una lettera maiuscola
                </li>
                <li
                  checkNumber
                  [ngClass]="checkNumber(changePasswordWithTokeForm.controls.newPassword.value) ? 'valid-text' : ''">
                  Deve contenere almeno un numero
                </li>
                <li
                  checkSpecialCharacter
                  [ngClass]="
                    checkSpecialCharacter(changePasswordWithTokeForm.controls.newPassword.value) ? 'valid-text' : ''
                  ">
                  Deve contenere almeno un carattere speciale
                </li>
              </ul>
            </label>
          }
        </div>
        <!-- Repeat New Password input -->
        <div class="form-outline mb-4">
          <input
            type="password"
            formControlName="repeatNewPassword"
            id="repeatNewPassword"
            [required]="true"
            class="form-control"
            [ngClass]="
              changePasswordWithTokeForm.controls.newPassword.valid &&
              changePasswordWithTokeForm.controls.repeatNewPassword.valid &&
              changePasswordWithTokeForm.controls.newPassword.value ==
                changePasswordWithTokeForm.controls.repeatNewPassword.value
                ? 'valid'
                : changePasswordWithTokeForm.controls.repeatNewPassword.value != null
                  ? 'wrong'
                  : ''
            "
            placeholder="Nuova password"
            required />
          @if (
            changePasswordWithTokeForm.controls.newPassword.valid &&
            changePasswordWithTokeForm.controls.repeatNewPassword.valid &&
            changePasswordWithTokeForm.controls.newPassword.value ==
              changePasswordWithTokeForm.controls.repeatNewPassword.value
              ? false
              : changePasswordWithTokeForm.controls.repeatNewPassword.value != null
                ? true
                : false
          ) {
            <label class="form-label wrong-text" for="newPassword">Le password non coincidono</label>
          }
        </div>
        <div class="col">
          <!-- Submit button -->
          <button
            type="submit"
            class="btn btn-outline-secondary offset-8 col-4"
            [disabled]="
              !changePasswordWithTokeForm.controls.newPassword.valid ||
              changePasswordWithTokeForm.controls.newPassword.value !=
                changePasswordWithTokeForm.controls.repeatNewPassword.value
            ">
            Invia
          </button>
        </div>
      </form>
    } @else {
      <form [formGroup]="changePasswordForm" class="border p-4 rounded-5" (ngSubmit)="submit()">
        <!-- Password input -->
        <div class="form-outline mb-4">
          <input
            type="password"
            formControlName="password"
            id="oldPasswordInput"
            class="form-control"
            placeholder="Password"
            [required]="true" />
        </div>
        <!-- New Password input -->
        <div class="form-outline mb-4">
          <input
            type="password"
            formControlName="newPassword"
            id="newPasswordInput"
            class="form-control"
            [ngClass]="
              changePasswordForm.get('newPassword')?.valid
                ? 'valid'
                : changePasswordForm.controls.newPassword.value
                  ? 'wrong'
                  : ''
            "
            placeholder="Nuova password"
            required />
          @if (!changePasswordForm.controls.newPassword.valid && changePasswordForm.controls.newPassword.value) {
            <label class="form-label wrong-text mt-2" for="newPassword">
              <ul>
                <li
                  checkLenght
                  [ngClass]="changePasswordForm.controls.newPassword.value.length > 7 ? 'valid-text' : ''">
                  La password deve essere di almeno 8 caratteri
                </li>
                <li
                  checkCapitalLetter
                  [ngClass]="checkCapitalLetter(changePasswordForm.controls.newPassword.value) ? 'valid-text' : ''">
                  Deve contenere almeno una lettera maiuscola
                </li>
                <li
                  checkNumber
                  [ngClass]="checkNumber(changePasswordForm.controls.newPassword.value) ? 'valid-text' : ''">
                  Deve contenere almeno un numero
                </li>
                <li
                  checkSpecialCharacter
                  [ngClass]="checkSpecialCharacter(changePasswordForm.controls.newPassword.value) ? 'valid-text' : ''">
                  Deve contenere almeno un carattere speciale
                </li>
              </ul>
            </label>
          }
        </div>
        <!-- Repeat New Password input -->
        <div class="form-outline mb-4">
          <input
            type="password"
            formControlName="repeatNewPassword"
            id="repeatNewPasswordInput"
            class="form-control"
            [ngClass]="
              changePasswordForm.controls.newPassword.valid &&
              changePasswordForm.controls.repeatNewPassword.valid &&
              changePasswordForm.controls.newPassword.value == changePasswordForm.controls.repeatNewPassword.value &&
              changePasswordForm.controls.repeatNewPassword.value
                ? 'valid'
                : changePasswordForm.controls.repeatNewPassword.value
                  ? 'wrong'
                  : ''
            "
            placeholder="Nuova password"
            required />
          @if (
            (changePasswordForm.controls.newPassword.valid &&
              changePasswordForm.controls.repeatNewPassword.valid &&
              changePasswordForm.controls.newPassword.value == changePasswordForm.controls.repeatNewPassword.value) ||
            (!changePasswordForm.controls.newPassword.value && !changePasswordForm.controls.repeatNewPassword.value)
              ? false
              : !changePasswordForm.controls.repeatNewPassword.value
                ? true
                : false
          ) {
            <label class="form-label wrong-text" for="repeatNewPassword">Le password non coincidono</label>
          }
        </div>
        <div class="col">
          <!-- Submit button -->
          <button
            type="submit"
            class="btn btn-outline-secondary offset-8 col-4"
            [disabled]="
              !changePasswordForm.controls.newPassword.valid ||
              changePasswordForm.controls.newPassword.value != changePasswordForm.controls.repeatNewPassword.value
            ">
            Invia
          </button>
        </div>
      </form>
    }
  </div>
</div>
