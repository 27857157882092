<nav class="navbar navbar-dark navbar-expand-lg d-flex justify-content-end p-0" style="height: 60px">
  <div class="container-fluid p-0">
    <div class="d-inline-flex">
      @if (!isOperation && customers.length > 0) {
        @if (customers.length <= 1) {
          <div
            class="cursor-pointer d-flex fw-semibold h5 align-items-center m-0 bg-174f84 ps-3 fs-6"
            style="width: 240px">
            @if (currentCustomer?.image) {
              <img
                class="rounded me-2 bg-white p-1"
                style="max-width: 40px; max-height: 40px"
                [src]="currentCustomer?.image"
                alt="city logo" />
            }
            {{ currentCustomer?.descrizione }}
          </div>
        } @else if (customers.length > 1) {
          <div
            class="cursor-pointer d-flex fw-semibold h5 align-items-center m-0 bg-174f84 ps-3 fs-6"
            autoClose="outside"
            [ngbTooltip]="popOverCustomerSelection"
            #popoverCustomer="ngbTooltip"
            triggers="manual"
            (click)="popoverCustomer.isOpen() ? popoverCustomer.close() : popoverCustomer.open()"
            style="width: 240px">
            @if (currentCustomer?.image) {
              <img
                class="rounded me-2 bg-white p-1"
                style="max-width: 40px; max-height: 40px"
                [src]="currentCustomer?.image"
                alt="city logo" />
            }
            {{ currentCustomer ? currentCustomer.descrizione : 'Sleziona un ente' }}
          </div>
        }

        <ng-template #popOverCustomerSelection>
          @for (customer of customers; track customer) {
            <div class="row">
              <button class="btn col fw-semibold text-start" (click)="changeCustomer(customer)">
                <small>{{ customer.descrizione }}</small>
              </button>
            </div>
          }
        </ng-template>
      }
      <div class="navbar-brand p-0 m-0 d-flex align-items-center">
        <!--        <div class="">-->
        <img src="assets/LogoTitanE.webp" alt="titano se logo" class="ms-1 mw-100 mh-100" />
        <!--        </div>-->
      </div>
      <div class="vr me-2 ms-2"></div>
      @if (currentPage) {
        <div class="d-flex h5 align-items-center m-0">
          <i class="me-2" [ngClass]="getIconFromCurrentPage()"></i>
          <span class="fw-semibold">{{ currentPage }}</span>
        </div>
      }
    </div>
    @if (username) {
      <div class="d-flex me-2 align-items-center" style="height: 60px">
        @if (!isOperation) {
          <button class="btn rounded bg-white p-1 ps-3 pe-3 text-primary fw-bold" (click)="openUploadPage()">
            <small>
              <i class="fa-solid fa-upload"></i>
              Carica flusso digitale
            </small>
          </button>
        }
        <div class="vr me-2 ms-2"></div>
        <button
          class="btn d-inline-flex align-items-center"
          autoClose="outside"
          [ngbTooltip]="popOverTemplate"
          #t="ngbTooltip"
          triggers="manual"
          (click)="t.isOpen() ? t.close() : t.open()">
          @if (userAvatar) {
            <div class="me-2">
              <img [src]="userAvatar" class="rounded-circle" style="width: 30px" alt="Avatar" />
            </div>
          }
          <div class="nav-link text-center text-white cursor-pointer fw-bold">
            <small>{{ username }}</small>
          </div>

          <ng-template #popOverTemplate>
            <div class="row">
              <button class="btn col fw-semibold text-start" (click)="logout()"><small> Logout</small></button>
            </div>
            <div class="row">
              <a class="btn col fw-semibold text-start" routerLink="/change-password"><small>Cambia password</small></a>
            </div>
            <div class="row">
              <a class="btn col fw-semibold text-start" routerLink="/uploaded"><small>Rilevazioni</small></a>
            </div>
          </ng-template>
        </button>
      </div>
    }
  </div>
</nav>

<div class="bg-e0ecf2" style="height: calc(100vh - 60px)">
  <router-outlet></router-outlet>
</div>

@if (toastInfo && toastInfo.isUploading && toastInfo.percentage) {
  <ngb-toast [autohide]="false" class="position-fixed">
    <ng-template ngbToastHeader>
      <strong class="col-11">Stato upload</strong>
    </ng-template>
    <div class="row">
      <!-- Contenuto del toast -->
      <div class="col-4 text-truncate">
        {{ toastInfo.nomeFile ? getNomeFile(toastInfo.nomeFile) : '' }}
      </div>
      <div class="col">
        <ngb-progressbar
          [showValue]="true"
          type="primary"
          [striped]="true"
          [animated]="true"
          [value]="toNumber(toastInfo.percentage.toFixed(2))"></ngb-progressbar>
      </div>
    </div>
  </ngb-toast>
}

<div class="ms-2 mb-1 fixed-bottom text-primary">V. {{ appVersion }}</div>

<app-toast-container aria-live="polite" aria-atomic="true"></app-toast-container>
