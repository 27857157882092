import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';
    
    // Dividi il valore in base al carattere di underscore
    const words = value.split('_');
    
    // Capitalizza la prima lettera di ogni parola e unisce i risultati
    const capitalizedWords = words.map(word => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1).toLowerCase();
      return firstLetter + restOfWord;
    });
    
    // Ritorna il testo con le parole capitalizzate separate da uno spazio
    return capitalizedWords.join(' ');
  }

}
