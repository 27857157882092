import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Upload } from 'src/app/model/upload';
import { UploadFileService } from 'src/app/service/upload-file.service';
import { UtilsService } from 'src/app/service/utils.service';
import { ToastService } from '../../service/toast.service';

@Component({
  selector: 'app-confirm-send',
  templateUrl: './confirm-send.component.html',
  styleUrls: ['./confirm-send.component.css'],
})
export class ConfirmSendComponent {
  upload: Upload | null = null;

  constructor(
    private route: ActivatedRoute,
    private uploadFileService: UploadFileService,
    private utilsService: UtilsService,
    private router: Router,
    private toastService: ToastService,
  ) {
    this.route.queryParams.subscribe((params) => {
      const id = params['id'] as number;

      if (id)
        this.uploadFileService.getUpload(id).subscribe({
          next: (resp) => {
            this.upload = resp;
          },
          error: (err) => this.utilsService.httpErrorMessage(err),
        });
    });
  }

  confirmUpload() {
    if (this.upload)
      this.uploadFileService.confirmUpload(this.upload).subscribe({
        next: (resp) => {
          if (resp && resp.cod === '000') {

            this.toastService.show({
              classname: 'bg-toast-success border-success',
              delay: 5000,
              innerHtml:
                `<span class="p-2 rounded-circle bg-d1ebe2 me-2"><i class="fa-regular fa-thumbs-up text-success fa-xl"></i></span>Il file <span class="fw-bold">
                      ${this.upload?.originalFilename}
                </span> è stato aggiunto correttamente.`,
            });

            this.router.navigateByUrl('uploaded');
            // });
          } else {
            this.toastService.show({
              classname: 'bg-toast-success border-success',
              delay: 5000,
              innerHtml:
                `<span class="p-2 rounded-circle bg-d1ebe2 me-2"><i class="fa-regular fa-thumbs-up text-success fa-xl"></i></span>Operazione eseguita con successo`,
            });
          }
        },
        error: (errResp) => this.utilsService.httpErrorMessage(errResp),
      });
  }

  deleteUpload() {
    if (this.upload?.id)
      this.uploadFileService.deleteFile(this.upload.id).subscribe({
        next: (resp) => {
          if (resp && resp.cod === '000') {

            this.toastService.show({
              classname: 'bg-toast-success border-success',
              delay: 5000,
              innerHtml:
                `<span class="p-2 rounded-circle bg-d1ebe2 me-2"><i class="fa-regular fa-thumbs-up text-success fa-xl"></i></span>Il file è stato eliminato.`,
            });

          } else {
            this.toastService.show({
                closeButtonClass: 'text-danger',
                delay: 1000000,
                classname: 'bg-toast-error border-danger text-danger fw-semibold',
                innerHtml: `<span class="p-2 me-2"><i class="fa-solid fa-circle-exclamation fa-xl"></i></span>${resp.message}<br>Cod: ${resp.cod}`,
              },
            );
          }
        },
        error: (errResp) => this.utilsService.httpErrorMessage(errResp),
      });
  }
}
