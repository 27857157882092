import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CustomerInfo } from '../model/customer-info';
import * as string_decoder from 'string_decoder';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  customers: BehaviorSubject<CustomerInfo[]> = new BehaviorSubject<
    CustomerInfo[]
  >([]);
  username: BehaviorSubject<{ username?: string, userAvatar?:string }> = new BehaviorSubject<{ username?: string, userAvatar?:string }>({});
  customerCode: BehaviorSubject<CustomerInfo | null> =
    new BehaviorSubject<CustomerInfo | null>(null);
  userGroup: BehaviorSubject<string> = new BehaviorSubject<string>('');

  userMenu: BehaviorSubject<{ label: string; route: string }[]> =
    new BehaviorSubject<{ label: string; route: string }[]>([]);

  uploadStatus: BehaviorSubject<{
    isUploading: boolean;
    percentage: number | null;
    uploadForm: FormGroup | null;
    nomeFile: string | null;
  }> = new BehaviorSubject<{
    isUploading: boolean;
    percentage: number | null;
    uploadForm: FormGroup | null;
    nomeFile: string | null;
  }>({
    isUploading: false,
    percentage: null,
    uploadForm: null,
    nomeFile: null,
  });

  constructor() {}
}
