import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EucarisInfo } from '../model/eucaris-info';

@Injectable({
  providedIn: 'root',
})
export class EucarisService {
  constructor(private httpClient: HttpClient) {}

  getEucarisInfo(customerCode: string): Observable<EucarisInfo> {
    return this.httpClient.get<EucarisInfo>(
      `${environment.baseUrl}${environment.credentialsPath}info`,
      {
        params: {
          customerCode,
        },
      }
    );
  }
}
