<div class="bg-white pt-4" style="height: calc(100vh - 60px)">
  <!--  <div class="d-flex justify-content-center pb-4">-->
  <!--    <div class="text-xl-center title">Password dimenticata</div>-->
  <!--  </div>-->

  <div class="d-flex justify-content-center">
    <form [formGroup]="forgotCredentialForm" class="border p-4 rounded-5" (ngSubmit)="submit()">
      <!-- email input -->
      <div class="form-outline mb-4">
        <input
          type="email"
          formControlName="email"
          id="emailInput"
          class="form-control"
          [ngClass]="
            !forgotCredentialForm.controls.email.valid && forgotCredentialForm.controls.email.value
              ? 'wrong'
              : forgotCredentialForm.controls.email.value
                ? 'valid'
                : ''
          "
          [email]="true"
          placeholder="Email" />
      </div>

      <div class="col">
        <!-- Submit button -->
        <button type="submit" [disabled]="!forgotCredentialForm.valid" class="btn btn-outline-secondary offset-8 col-4">
          Invia
        </button>
      </div>

      <div>
        <a routerLink="/login" class="col-8 text-sm-left link-secondary"> Torna alla login </a>
      </div>
    </form>
  </div>
</div>
