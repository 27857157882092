<!-- Date range picker -->
<div class="dp-hidden position-absolute">
  <div class="input-group position-static">
    <input
      name="datepicker"
      class="form-control"
      style="z-index: -999"
      ngbDatepicker
      #datepicker="ngbDatepicker"
      [autoClose]="'outside'"
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="2"
      [dayTemplate]="t"
      outsideDays="hidden"
      [startDate]="fromDate!"
      tabindex="-1" />
    <ng-template #t let-date let-focused="focused">
      <span
        class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
        {{ date.day }}
      </span>
    </ng-template>
  </div>
</div>
<div class="row">
  <div class="input-group position-static col">
    <input
      #dpFromDate
      class="form-control position-static"
      [placeholder]="placeholder.da"
      name="dpFromDate"
      [value]="formatter.format(fromDate)"
      (input)="fromDate = validateBefore(fromDate, dpFromDate.value, toDate, da)" />
    <button
      class="btn fa-regular fa-calendar position-static"
      [ngClass]="buttonClass"
      style="z-index: unset"
      (click)="datepicker.toggle()"
      type="button"></button>
  </div>
  <div class="input-group position-static col">
    <input
      #dpToDate
      class="form-control position-static"
      [placeholder]="placeholder.al"
      name="dpToDate"
      [value]="formatter.format(toDate)"
      (input)="toDate = validateAfter(toDate, dpToDate.value, fromDate, al)"
      (keyup)="backSpaceCheck($event)" />
    <button
      class="btn btn-outline-secondary fa-regular fa-calendar position-static"
      style="z-index: unset"
      [ngClass]="buttonClass"
      (click)="datepicker.toggle()"
      type="button"></button>
  </div>
</div>
