import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomerInfo } from '../model/customer-info';
import { Response } from '../model/response';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  afterLoginRedirect: string | undefined;

  refreshCookieTimeoutID: any;

  constructor(private httpClient: HttpClient) {
  }

  // TODO: implementare chiamata al backend
  login(username: string, password: string): Observable<Response> {
    const body = new URLSearchParams();
    body.append('username', username);
    body.append('password', password);

    const options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded',
      ),
    };

    return this.httpClient.post(
      `${environment.baseUrl}${environment.authPath}login`,
      body,
      options,
    ) as Observable<Response>;
    // return of(new Response('Cambia password', '001'));
  }

  getUserInfo() {
    return this.httpClient.get(
      `${environment.baseUrl}${environment.authPath}userInfo`,
    ) as Observable<{
      username: string;
      userAvatar: string
      customers: CustomerInfo[];
      userGroup: string;
      userMenus: {
        label: string;
        route: string;
      }[];
    }>;
  }

  async checkRoutePermission(route: string) {
    return await firstValueFrom(
      this.httpClient.get<boolean>(
        `${environment.baseUrl}${environment.authPath}isAllowedRoute`,
        {
          params: { route },
        },
      ),
    );
  }

  // getUserMenu() {
  //   return this.httpClient.get(
  //     `${environment.baseUrl}${environment.authPath}userMenu`
  //   ) as Observable<
  //     {
  //       label: string;
  //       route: string;
  //     }[]
  //   >;
  // }

  logout(): Observable<Response> {
    return this.httpClient.post(
      `${environment.baseUrl}${environment.authPath}logout`,
      null,
    ) as Observable<Response>;

    // return of(new Response('', '000'));
  }

  forgotCredential(email: string): Observable<Response> {
    const body = new URLSearchParams();
    body.set('email', email);

    const options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded',
      ),
    };

    return this.httpClient.post(
      `${environment.baseUrl}${environment.authPath}forgotPassword`,
      body,
      options,
    ) as Observable<Response>;
  }

  async isAuth() {
    return await firstValueFrom(
      this.httpClient.get<boolean>(
        `${environment.baseUrl}${environment.authPath}isAuth`,
      ),
    );
  }

  checkAuth() {
    return this.httpClient.get(
      `${environment.baseUrl}${environment.authPath}isAuth`,
    );
  }

  checkUserGroup() {
    return this.httpClient.get(
      `${environment.baseUrl}${environment.authPath}userGroup`,
      { responseType: 'text' },
    );
  }

  changePassword(
    oldPassword: string,
    newPassword: string,
  ): Observable<Response> {
    const body = new URLSearchParams();
    body.set('password', oldPassword);
    body.set('newPassword', newPassword);

    const options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded',
      ),
    };

    return this.httpClient.post(
      `${environment.baseUrl}${environment.authPath}changePass`,
      body,
      options,
    ) as Observable<Response>;
  }

  changePasswordWithToken(
    toke: string,
    newPassword: string,
  ): Observable<Response> {
    const body = new URLSearchParams();
    body.set('passToken', toke);
    body.set('newPassword', newPassword);

    const options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded',
      ),
    };

    return this.httpClient.post(
      `${environment.baseUrl}${environment.authPath}changePass/withToke`,
      body,
      options,
    ) as Observable<Response>;
  }

  changeEmail(email: string) {
    const body = new URLSearchParams();
    body.set('email', email);

    const options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded',
      ),
    };

    return this.httpClient.post<Response>(
      `${environment.baseUrl}${environment.authPath}changeEmail`,
      body,
      options,
    );
  }

  updateCookie() {
    return this.httpClient.get(
      `${environment.baseUrl}${environment.authPath}refreshToken`,
    );
  }

  autoUpdateCookie() {
    this.refreshCookieTimeoutID = setTimeout(() => {
      this.updateCookie().subscribe();
      this.autoUpdateCookie();
    }, 1500 * 1000);
  }

  checkAutoUpdateCookie() {
    if (!this.refreshCookieTimeoutID) {
      this.updateCookie().subscribe();
      this.autoUpdateCookie();
    }
  }

  getCurrentEmail() {
    return this.httpClient.get<{ email: string }>(
      `${environment.baseUrl}${environment.authPath}currentEmail`,
    );
  }
}
