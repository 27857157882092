@for (toast of toastService.toasts; track toast) {
  <ngb-toast
    [class]="toast.classname"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="toastService.remove(toast)">
    <div class="d-flex justify-content-between">
      @if (toast.template) {
        <ng-template [ngTemplateOutlet]="toast.template"></ng-template>
      }

      @if (toast.innerHtml) {
        <div [innerHTML]="toast.innerHtml"></div>
      }

      <button
        class="btn p-0 ms-2"
        [class]="toast.closeButtonClass ? toast.closeButtonClass : []"
        (click)="toastService.remove(toast)">
        <small><i class="fa-regular fa-x"></i></small>
      </button>
    </div>
  </ngb-toast>
}
