import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Response } from '../model/response';
import { Upload } from '../model/upload';
import { UploadDto } from '../model/upload-dto';
import { Traking } from '../model/traking';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  constructor(private httpClient: HttpClient) {}

  uploadFile(
    uploadForm: FormData,
    customerCode: string
  ): Observable<Response> {
    return this.httpClient.post<Response>(
      `${environment.baseUrl}${environment.flussoPath}upload`,
      uploadForm,
      {
        params: {
          customerCode,
        },
      }
    ) ;
  }

  getUpload(id: number): Observable<Upload> {
    return this.httpClient.get(
      `${environment.baseUrl}${environment.flussoPath}${id}`
    ) as Observable<Upload>;
  }

  deleteFile(idFile: string | number): Observable<Response> {
    return this.httpClient.delete(
      `${environment.baseUrl}${environment.flussoPath}delete/${idFile}`
    ) as Observable<Response>;
  }

  confirmUpload(upload: Upload) {
    return this.httpClient.post(
      `${environment.baseUrl}${environment.flussoPath}confirm/upload`,
      upload
    ) as Observable<Response>;
  }

  getTraking(idFlusso: number) {
    return this.httpClient.get<Traking[]>(
      `${environment.baseUrl}${environment.flussoPath}traking/${idFlusso}`
    );
  }

  updateState(updateUpload: {
    id: number;
    stateCod: string;
    processingTypeCod: string | null;
  }) {
    return this.httpClient.put<any>(
      `${environment.baseUrl}${environment.flussoPath}update`,
      updateUpload
    );
  }

  getUploadsType() {
    return this.httpClient.get<string[]>(
      `${environment.baseUrl}${environment.flussoPath}uploadsType`
    );
  }

  getUploaded(
    customerCode: string | null,
    size: number | null,
    page: number | null,
    sort: { field: string; dir?: 'asc' | 'desc' } | null,
    filter: {}
  ) {
    let params: any = {};
    if (page && size) {
      params = {
        size,
        page,
      };
    }

    if (sort) {
      params['sortDir'] = sort.dir;
      params['sortField'] = sort.field;
    }

    if (customerCode) {
      params['customerCode'] = customerCode;
    }

    return this.httpClient.post<{
      data: UploadDto[];
      totalElement: number;
      lastPage: number;
      currentPage: number;
    }>(`${environment.baseUrl}${environment.flussoPath}uploaded`, filter, {
      params: params,
    });
  }

  getUploadedCsv(
    customerCode: string | null,
    size: number | null,
    page: number | null,
    filter: {}
  ) {
    let params: any = {};

    if (page && size) {
      params = {
        size,
        page,
      };
    }

    if (customerCode) {
      params['customerCode'] = customerCode;
    }

    return this.httpClient.post(
      `${environment.baseUrl}${environment.flussoPath}csv/uploaded`,
      filter,
      {
        responseType: 'blob',
        params: params,
      }
    );
  }
}
