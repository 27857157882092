import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  constructor(private httpClient: HttpClient) {}

  getState() {
    return this.httpClient.get(
      `${environment.baseUrl}${environment.statePath}`
    ) as Observable<
      {
        cod: string;
        description: string;
        nextState: null | { cod: string; description: string };
        alwaysActivable: boolean;
      }[]
    >;
  }
}
