<div class="bg-white pt-4" style="height: calc(100vh - 60px)">
  <!--  <div class="d-flex justify-content-center">-->
  <!--    <div class="text-xl-center title">Login</div>-->
  <!--  </div>-->
  <div class="d-flex justify-content-center">
    <form [formGroup]="loginForm" class="border p-4 rounded-5" (ngSubmit)="submit()">
      <!-- Email input -->
      <div class="form-outline mb-4">
        <input
          type="text"
          formControlName="username"
          id="emailInput"
          class="form-control"
          placeholder="Username"
          required />
      </div>

      <div class="form-outline mb-4">
        <input
          type="password"
          formControlName="password"
          id="passwordInput"
          class="form-control"
          placeholder="Password"
          required />
      </div>

      <div class="col">
        <button type="submit" [disabled]="!loginForm.valid" class="btn btn-outline-secondary offset-8 col-4">
          Entra
        </button>
      </div>
      <div>
        <a routerLink="/forgot-password" class="col-8 text-sm-left link-secondary"> Password dimenticata </a>
      </div>
    </form>
  </div>
</div>
