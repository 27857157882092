import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/service/utils.service';
import { LoginService } from '../../service/login.service';
import { UserService } from 'src/app/service/user.service';
import { ToastService } from '../../service/toast.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent {
  changePasswordForm = new FormGroup({
    password: new FormControl(),
    newPassword: new FormControl('', [
      Validators.required,
      Validators.pattern(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/,
      ),
    ]),
    repeatNewPassword: new FormControl(''),
  });

  changePasswordWithTokeForm = new FormGroup({
    newPassword: new FormControl('', [
      Validators.required,
      Validators.pattern(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/,
      ),
    ]),
    repeatNewPassword: new FormControl(),
  });
  token: string | null = null;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private userService: UserService,
  ) {
    this.token = this.route.snapshot.paramMap.get('token');
  }

  ngOnInit() {
  }

  checkCapitalLetter(str: string) {
    return str.match(/[A-Z]/);
  }

  checkNumber(str: string) {
    return str.match(/[0-9]/);
  }

  checkSpecialCharacter(str: string) {
    return str.match(/[@$!%*#?&^_-]/);
  }

  submit() {
    if (
      this.token &&
      this.changePasswordWithTokeForm.valid &&
      this.changePasswordWithTokeForm.controls.newPassword.value ===
      this.changePasswordWithTokeForm.controls.repeatNewPassword.value
    ) {
      this.loginService
        .changePasswordWithToken(
          this.token,
          this.changePasswordWithTokeForm.controls.newPassword.value
            ? this.changePasswordWithTokeForm.controls.newPassword.value
            : '',
        )
        .subscribe({
          next: (resp) => {
            this.toastService.show({
              classname: 'bg-toast-success border-success',
              delay: 5000,
              innerHtml:
                `<span class="p-2 rounded-circle bg-d1ebe2 me-2"><i class="fa-regular fa-thumbs-up text-success fa-xl"></i></span>Operazione eseguita con successo`,
            });
            this.router.navigate([
              /* this.userService.userMenu.value[0].route,*/ 'uploaded',
            ]);
          },
          error: (errResp) => this.utilsService.httpErrorMessage(errResp),
        });
    } else if (
      !this.token &&
      this.changePasswordForm.valid &&
      this.changePasswordForm.controls.newPassword.value ===
      this.changePasswordForm.controls.repeatNewPassword.value
    ) {
      this.loginService
        .changePassword(
          this.changePasswordForm.controls.password.value,
          this.changePasswordForm.controls.newPassword.value
            ? this.changePasswordForm.controls.newPassword.value
            : '',
        )
        .subscribe({
          next: (resp) => {

            this.toastService.show({
              classname: 'bg-toast-success border-success',
              delay: 5000,
              innerHtml:
                `<span class="p-2 rounded-circle bg-d1ebe2 me-2"><i class="fa-regular fa-thumbs-up text-success fa-xl"></i></span>Operazione eseguita con successo`,
            });


            this.router.navigate([
              /*  this.userService.userMenu.value[0].route,*/ 'uploaded',
            ]);
          },
          error: (errResp) => this.utilsService.httpErrorMessage(errResp),
        });
    }
  }
}
