<div class="modal-header border-0 pb-0">
  <h4 class="modal-title fs-5 fw-bold" id="modal-basic-title">{{ title }}</h4>
  @if (!hiddenCloseButton) {
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  }
</div>
<div class="modal-body">
  <div class="text-justify">
    <p>{{ message }}</p>
    <p class="text-right" [hidden]="!isError">
      Codice errore: <span class="wrong-text">{{ code }}</span>
    </p>
  </div>
</div>

<div class="modal-footer bg-e0ecf2 border-0" [hidden]="action == null">
  <button
    type="button"
    class="btn text-3c7eba fw-bold me-auto"
    [hidden]="!action?.leftActionButton"
    (click)="action?.leftActionButton?.action()">
    {{ action?.leftActionButton?.label }}
  </button>

  <button
    type="button"
    class="btn btn-outline-dark btn-256eb3 border-0 fw-bold"
    [hidden]="!action?.rigthActionButton"
    (click)="action?.rigthActionButton?.action()">
    {{ action?.rigthActionButton?.label }}
  </button>
</div>
