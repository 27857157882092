export const environment = {
  // baseUrl: 'http://74.234.235.18:80/',
  baseUrl: 'https://titano-se-test.portaleinfo.it/',
  authPath: 'v1/auth/',
  flussoPath: 'v1/flusso/',
  statePath: 'v1/state/',
  processingTypePath: 'v1/processingType/',
  credentialsPath: 'v1/credential/',
  uploadFolderName: 'uploads',
  tmpFolderName: 'tmp',
  blobStoragePath: 'v1/blob/',

  production: false,
};
