import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AzureBlobTokenInfo } from '../model/azure-blob-token-info';

@Injectable({
  providedIn: 'root',
})
export class BlobStorageService {
  constructor(private httpClient: HttpClient) {}

  getBlobToken(codCliente: string): Observable<AzureBlobTokenInfo> {
    return this.httpClient.get(
      `${environment.baseUrl}${environment.blobStoragePath}sasToken`,
      {
        params: { codCliente },
      }
    ) as Observable<AzureBlobTokenInfo>;
  }
}
