<div class="modal-header bg-e0ecf2">
  <h4 class="modal-title ms-1 row fw-bold color-256eb3" id="modal-basic-title">Caricamento flusso digitale</h4>
  <button
    type="button"
    class="btn btn-primary rounded-circle d-flex align-items-center round-button"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <i class="fa-solid fa-x"></i>
  </button>
</div>

<div class="modal-body p-4">
  <form [formGroup]="uploadFileForm" enctype="multipart/form-data">
    <div class="border p-4">
      <small class="text-secondary fs-6"> Formati supportati: .zip, .csv </small>
      <!--File input drag & drop-->
      <app-drag-and-drop-file
        [fileControl]="uploadFileForm.controls.file"
        [fileErrorMessage]="fileErrorMessage"
        [lastUploadedFile]="lastUploadedFile"
        [(nomeFile)]="nomeFile"
        [uploading]="uploading"
        [acceptedType]="[
          'text/csv',
          'application/zip',
          'multipart/x-zip',
          'application/x-zip-compressed',
          'application/octet-stream'
        ]">
      </app-drag-and-drop-file>

      <div class="row mb-2">
        <div class="col fs-5 text-primary fw-bold">Informazioni flussi</div>
      </div>
      <div class="row mb-4">
        <!-- Uploads type input -->
        <div class="form-outline col">
          <select class="form-select" aria-label="Default select example" formControlName="type" [required]="true">
            <option *ngFor="let type of uploadsType" [value]="type">
              {{ type === '' ? 'Seleziona tipo' : type.replaceAll('_', ' ') }}
            </option>
          </select>
        </div>

        <!-- Num verbali input -->
        <div class="form-outline col">
          <input
            type="number"
            formControlName="nVerbali"
            [ngClass]="
              uploadFileForm.controls.nVerbali.value && !uploadFileForm.controls.nVerbali.valid
                ? 'incorrect-format'
                : ''
            "
            id="numVerbaliInput"
            [required]="true"
            [min]="1"
            class="form-control"
            placeholder="Numero di verbali" />
          <label
            *ngIf="uploadFileForm.controls.email.value && !uploadFileForm.controls.email.valid"
            class="form-label incorrect-format"
            for="numVerbaliInput"
            >Inserisci il numero di verbali</label
          >
        </div>
      </div>

      <div class="row">
        <div class="form-outline mb-4 border-0 col">
          <app-date-range-picker
            #daAlDatePicker
            class="form-outline mb-4"
            [placeholder]="{ da: 'Data primo verbale', al: 'Data ultimo verbale (Scadenza)' }"
            [da]="uploadFileForm.controls.da"
            [al]="uploadFileForm.controls.al"></app-date-range-picker>
        </div>
      </div>

      <!-- Tracking change state input -->
      <!--    <div class="custom-control custom-checkbox mb-4 form-outline">-->
      <!--      <input type="checkbox" formControlName="trackingStateChange" class="custom-control-input" />-->
      <!--      <label class="ms-2 custom-control-label" for="trackingStateChange"-->
      <!--      >Tienimi aggiornato sulle modifiche di stato</label-->
      <!--      >-->
      <!--    </div>-->

      <div class="row mb-2">
        <div class="col fs-5 text-primary fw-bold">Credenziali</div>
      </div>

      <div class="row mb-4">
        <!-- Email input -->
        <div class="form-outline col">
          <input
            type="email"
            formControlName="email"
            [ngClass]="
              uploadFileForm.controls.email.value && !uploadFileForm.controls.email.valid ? 'incorrect-format' : ''
            "
            [email]="true"
            id="emailInput"
            [required]="true"
            class="form-control"
            placeholder="Email per la conferma di caricamento" />
          <label
            *ngIf="uploadFileForm.controls.email.value && !uploadFileForm.controls.email.valid"
            class="form-label incorrect-format"
            for="emailInput"
            >Inserisci un'email valida</label
          >
        </div>
      </div>

      @if (eucarisInfo?.eucarisCredentials) {
        <div class="row">
          <!-- Eucaris username input -->
          <div class="form-outline col">
            <input
              type="text"
              formControlName="eucarisUsername"
              [ngClass]="eucarisUsernameErrorMessage.length > 0 ? 'incorrect-format' : ''"
              id="usernameInput"
              class="form-control"
              [placeholder]="eucarisInfo?.eucarisUser ? eucarisInfo?.eucarisUser : 'Eucaris user'" />
            <label
              *ngIf="eucarisUsernameErrorMessage.length > 0"
              class="form-label incorrect-format"
              for="usernameInput"
              >{{ eucarisUsernameErrorMessage }}</label
            >
          </div>

          <!-- Eucaris password input -->
          <div class="form-outline col">
            <div class="input-group">
              <input
                [type]="showEucarisPassword ? 'text' : 'password'"
                formControlName="eucarisPassword"
                [ngClass]="eucarisPasswordErrorMessage.length > 0 ? 'incorrect-format' : ''"
                id="eucarisPasswordInput"
                class="form-control border-end-0"
                [placeholder]="eucarisInfo?.eucarisUser ? '*******' : 'Eucaris password'" />
              <label
                *ngIf="eucarisPasswordErrorMessage.length > 0"
                class="form-label incorrect-format"
                for="eucarisPasswordInput"
                >{{ eucarisPasswordErrorMessage }}</label
              >
              <button
                class="btn fa-regular fa-eye border border-start-0 input-button"
                [disabled]="uploadFileForm.controls.eucarisPassword.disabled"
                (click)="showEucarisPassword = !showEucarisPassword"></button>
            </div>
          </div>
          <div class="col-1 ps-0">
            <button class="btn btn-primary w-100" (click)="editEucarisCredential()">
              <i
                class="fa-solid"
                [ngClass]="uploadFileForm.controls.eucarisPassword.disabled ? ['fa-pen-to-square'] : ['fa-x']"></i>
            </button>
          </div>
        </div>
        <small class="row mb-3 mt-1 ps-4">
          <div class="col text-secondary">Credenziali caricate da {{ eucarisInfo?.eucarisInsertDays }} giorni.</div>
        </small>
      }

      @if (eucarisInfo?.vpnCredentials) {
        <div class="row">
          <!-- VPN username input -->
          <div class="form-outline col">
            <input
              type="text"
              formControlName="vpnUsername"
              [ngClass]="vpnUsernameErrorMessage.length > 0 ? 'incorrect-f VPN userormat' : ''"
              id="vpnUsernameInput"
              class="form-control"
              [placeholder]="eucarisInfo?.vpnUser ? eucarisInfo?.vpnUser : 'VPN user'" />
            <label
              *ngIf="vpnUsernameErrorMessage.length > 0"
              class="form-label incorrect-format"
              for="vpnUsernameInput"
              >{{ vpnUsernameErrorMessage }}</label
            >
          </div>

          <!-- VPN password input -->
          <div class="form-outline col">
            <div class="input-group">
              <input
                [type]="showVPNPassword ? 'text' : 'password'"
                formControlName="vpnPassword"
                [ngClass]="vpnPasswordErrorMessage.length > 0 ? 'incorrect-format' : ''"
                id="vpnPasswordInput"
                class="form-control border-end-0"
                [placeholder]="eucarisInfo?.vpnUser ? '*******' : 'VPN password'" />
              <label
                *ngIf="vpnPasswordErrorMessage.length > 0"
                class="form-label incorrect-format"
                for="vpnPasswordInput"
                >{{ vpnPasswordErrorMessage }}</label
              >
              <button
                class="btn fa-regular fa-eye border border-start-0 input-button"
                [disabled]="uploadFileForm.controls.vpnPassword.disabled"
                (click)="showVPNPassword = !showVPNPassword"></button>
            </div>
          </div>

          <div class="col-1 ps-0">
            <button class="btn btn-primary w-100" (click)="editVPNPassword()">
              <i
                class="fa-solid"
                [ngClass]="uploadFileForm.controls.vpnPassword.disabled ? ['fa-pen-to-square'] : ['fa-x']"></i>
            </button>
          </div>
        </div>

        <small class="row mb-3 mt-1 ps-4">
          <div class="col text-secondary">
            Credenziali caricate da {{ eucarisInfo?.vpnCredentialsInsertDays }} giorni.
          </div>
        </small>
      }
      <ng-container *ngIf="!hiddenEucaris || !hiddenVpnCredentials">
        <div>
          <p class="text-lg font-weight-bolder">Informativa</p>
          <p class="text-size-small">
            Con la presente si autorizza S21 in nome e per conto dell'ente richiedente all'utilizzo delle credenziali
            inserite per espletare le procedure di visurazione.
          </p>
        </div>

        <div class="custom-control custom-checkbox d-flex flex-row-reverse mb-4 form-outline">
          <label class="custom-control-label ms-2" for="privacyPolicyCheck">Accetto</label>
          <input
            type="checkbox"
            formControlName="privacyPolicy"
            class="custom-control-input"
            [ngClass]="privacyErrorMessage.length > 0 ? 'incorrect-format' : ''"
            id="privacyPolicyCheck" />
        </div>
        <p *ngIf="privacyErrorMessage" class="incorrect-format">
          {{ privacyErrorMessage }}
        </p>
      </ng-container>

      <div class="progress mb-4 rounded-pill h-86rem" [hidden]="!uploading">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          #progressBar
          style="width: 0"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"></div>
      </div>
    </div>

    <div class="d-flex justify-content-between mt-4">
      <button class="btn text-primary fw-bold" (click)="activeModal.close()">Annulla</button>
      <button
        [disabled]="disableUpload()"
        (click)="submit()"
        class="btn btn-primary border-0 ps-3 pe-3 text-white fw-bold">
        Inserisci
      </button>
    </div>
  </form>
</div>

<!--<div *ngIf="loader" class="overlay d-flex h-100 w-100 justify-content-center align-items-center bg-black">-->
<!--  <div class="spinner-border text-primary" style="width: 3rem; height: 3rem" role="status"></div>-->
<!--</div>-->

<!--<div class="d-flex justify-content-center mb-4">-->

<!--</div>-->
