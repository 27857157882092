import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  standalone: true,
  styleUrls: ['./message.component.css'],
})
export class MessageComponent {
  @Input() message: string = '';
  @Input() code: string = '';
  @Input() isError: boolean = false;
  @Input() title: string = '';
  @Input() hiddenCloseButton = true
  @Input() action?: {
    leftActionButton?: { label: string; action: Function };
    rigthActionButton?: { label: string; action: Function };
  };

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }
}
